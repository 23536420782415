import "styles/front.scss"
import '@glidejs/glide/dist/css/glide.core.min'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
// import 'jscroll'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free-solid'

const images = require.context('../images/', true);
// const imagePath = name => images(name, true);

window.onload = () => {
  // Menu
  let isActive = false;
  const bugerButton = document.querySelector('a.navbar-burger');
  const modal = document.querySelector('.modal');
  const html = document.querySelector('html');
  const body = document.body;
  bugerButton.addEventListener('click', function (e) {
    if (isActive) {
      this.classList.remove("is-active");
      modal.classList.remove("is-active");

      isActive = false;

      html.style.overflow = 'auto';
      body.style.overflow = 'auto';
      document.removeEventListener('touchmove', cancelTouchmove(e), { passive: false });
    } else {
      this.classList.add("is-active");
      modal.classList.add("is-active");

      isActive = true;

      html.style.overflow = 'hidden';
      body.style.overflow = 'hidden';
      document.addEventListener('touchmove', cancelTouchmove(e), { passive: false });
    }
  });

  // Contact
  const contactGenreGroup = document.getElementById('contact_genre_group');
  if (contactGenreGroup) {
    // Scroll
    const errorMessage = document.querySelector('.message.is-danger');
    if (errorMessage) {
      const contactForm = document.querySelector('form.contact-form');
      const contactFormTop = contactForm.getBoundingClientRect().top;
      window.scrollTo({
        top: (contactFormTop - 10),
        left: 0,
        behavior: "smooth",
      });
    }

    // Contact.genre
    const contactGenre = document.getElementById('contact_genre');
    const cautionTextGroup = document.querySelector('.genre-group-box .help');
    const cautionTextGenre = document.querySelector('.genre-box .help');
    const genreOptions = document.querySelectorAll('.genre-box option');
    const formInputs = document.querySelectorAll('.contact-form input, .contact-form textarea');
    // genre group
    contactGenreGroup.onchange = (e) => {
      enableForm();
      const selectedValue = e.target.value;
      changeGenreOption(selectedValue);
      changeCautionTextGroup(selectedValue);
    }
    const changeGenreOption = group => {
      genreOptions.forEach((option) => {
        if (group != "" && option.value.indexOf(group) == 0) {
          option.style.display = 'block';
          option.disabled = false;
        } else {
          option.style.display = 'none';
          option.disabled = true;
        }
        option.selected = false;
      });
    };
    const changeCautionTextGroup = group => {
      switch(group) {
        case "order":
          cautionTextGroup.textContent = "※個人の方のご注文は受け付けておりません。";
          break;
        case "unko_gakuen":
          cautionTextGroup.innerHTML = 'うんこ学園のお問い合わせはうんこ学園公式サイトからお願い致します。<br><a href="https://unkogakuen.zendesk.com/hc/ja" target="_blank">うんこ学園</a>';
          disableForm();
          break;
        case "other":
          cautionTextGroup.textContent = "営業メールはお断りしております。";
          break;
        default:
          cautionTextGroup.textContent = "";
      }
      cautionTextGenre.textContent = "";
    }

    // genre
    contactGenre.onchange = (e) => {
      const selectedValue = e.target.value;
      changeCautionTextGenre(selectedValue);
    }
    const changeCautionTextGenre = genre => {
      switch(genre) {
        case "publicity_personal":
          cautionTextGenre.textContent = "SNS、要約サイト、読み聞かせなどに関する問い合わせはこちらからお願いします。";
          enableForm();
          break;
        case "publicity_library":
          cautionTextGenre.textContent = "HPを含む書籍紹介は申請不要です。それ以外の問い合わせはこちらからお願いします。";
          enableForm();
          break;
        case "business_ng":
          cautionTextGenre.textContent = "新規のご紹介、営業は受け付けておりません。";
          disableForm();
          break;
        default:
          cautionTextGenre.textContent = "";
          enableForm();
      }
    }
    const disableForm = () => {
      formInputs.forEach((input) => {
        input.disabled = true;
      });
    };
    const enableForm = () => {
      formInputs.forEach((input) => {
        input.disabled = false;
      });
    };

    // dispatchEvent
    const event = new Event('change');
    contactGenreGroup.dispatchEvent(event);
  }

  // Smooth Link
  smoothLink();

  // Carousel
  if (document.querySelector('.glide')) {
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 5,
      autoplay: 5000,
      breakpoints: {
        768: {
          perView: 2
        }
      }
    }).mount();
  }

  // Modal (Book Tameshiyome)
  // const tameshiyomeButton = document.querySelector('.tameshiyome-button');
  // if (tameshiyomeButton) {
  //   const rootElm = document.documentElement;
  //   const tameshiyomeModal = document.querySelector('.tameshiyome-modal');
  //   const tameshiyomeModalBackground = document.querySelector('.tameshiyome-modal-background');
  //   const tameshiyomeCloseButton = document.querySelector('.tameshiyome-close-button');
  //   tameshiyomeButton.addEventListener('click', (e) => {
  //     tameshiyomeModal.classList.add('is-active');
  //     rootElm.classList.add('is-clipped');
  //   });
  //   tameshiyomeModalBackground.addEventListener('click', (e) => {
  //     closeTameshiyomeModal();
  //   });
  //   tameshiyomeCloseButton.addEventListener('click', (e) => {
  //     closeTameshiyomeModal();
  //   });
  //   document.addEventListener('keydown', (e) => {
  //     if (e.key === 'Escape' || e.code === 'Escape') {
  //       closeTameshiyomeModal();
  //     }
  //   });
  //   const closeTameshiyomeModal = () => {
  //     tameshiyomeModal.classList.remove('is-active');
  //     rootElm.classList.remove('is-clipped');
  //   }
  // }
}

// Infinite Scroll (paging)
// $(function () {
//   $('.jscroll').jscroll({
//     // debug: true,
//     padding: 20,
//     nextSelector: 'a.next-link',
//     contentSelector: '.jscroll-list',
//     loadingHtml: '<div class="scroll-loading"></div>',
//   });
//   if ($('.jscroll').length) {
//     $('body, html').animate({ scrollTop: 0 }, 500);
//   }
// });

function cancelTouchmove(e) {
  e.preventDefault();
}

function smoothLink(headH = 0) {
  const interval = 10;
  const divisor = 8;
  const range = (divisor / 2) + 1;
  const links = document.querySelectorAll('a[href^="#"]');

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (e) {
      e.preventDefault();
      let toY;
      let nowY = window.pageYOffset;
      const href = e.target.getAttribute('href');
      const target = (href == '#' || href == '') ? 'html' : href;
      const targetElm = document.querySelector(target);
      const targetRect = targetElm.getBoundingClientRect();
      const targetY = targetRect.top + nowY - headH;
      (function doScroll() {
        toY = nowY + Math.round((targetY - nowY) / divisor);
        window.scrollTo(0, toY);
        nowY = toY;
        if (document.body.clientHeight - window.innerHeight < toY) {
          window.scrollTo(0, document.body.clientHeight);
          return;
        }
        if (toY >= targetY + range || toY <= targetY - range) {
          window.setTimeout(doScroll, interval);
        } else {
          window.scrollTo(0, targetY);
        }
      })();
    });
  }
};
